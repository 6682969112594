import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import Swal from 'sweetalert2'

export default {
    name: "Map",
    data() {
        return {
            iconBuild:[require("@/assets/img/map/Build-High.png"),require("@/assets/img/map/Build-Small.png"),require("@/assets/img/map/Build-Home.png")],
            imgRegion:"",
            swiperA:new Swiper(),
            swiperB:new Swiper(),
            province: [],
            provinceShow: [],
            region: [],
            region2: [],
            region_all: [],
            building: [],
            buildinshow: [],
            buildinshowitem: [],
            currBuild:0,
            product: [],
            province_selected: "",
            type_build: [
                { id: 1, name_th: 'อาคารสำนักงาน',name_en: 'Office Building'  },
                { id: 2, name_th: 'ที่พักอาศัย',name_en: 'Home'  },
            ],
            selected_region: 1,
            baseFront: this.$baseFront,
            lang:this.$lang,
           
        }
    },
    methods: {
        openLink(url){
            //console.log(url)
            window.open(url, "_blank", "noreferrer");
        },
        openMap(url){
            //console.log(url)
            window.open(url, "_blank", "noreferrer");
        },
        onChange(event) {
            this.currBuild = 0
            this.fillterBuild();
        },
        back_zone(){
            
            
           
            this.selected_region--;
            if(this.selected_region < 1){
                this.selected_region = this.region_all.length;
            }
            this.callsection(this.selected_region)
        },
        next_zone(){
           
            
            //alert(this.selected_region)
            this.selected_region++;
            if(this.selected_region > this.region_all.length){
                this.selected_region = 1;
            }
            this.callsection(this.selected_region)
        },
        prev_build(){
            
            this.currBuild  =  this.currBuild - 1
            
            if(this.currBuild < 0){
                this.currBuild = this.buildinshow.length-1
            }
            
            this.showBuilditem();
        },
        next_build(){
           
            this.currBuild =  this.currBuild + 1
            
            if(this.currBuild > this.buildinshow.length - 1){
                
                this.currBuild = 0//this.buildinshow.length-1
            }
            
            this.showBuilditem();
        },
        showBuilditem(){
            this.buildinshowitem = []
            if(this.buildinshow.length > 0){
                this.buildinshowitem.push(this.buildinshow[this.currBuild])
            }
            if(this.buildinshowitem.length == 0){
                this.imgRegion = this.baseFront+(this.lang == 'th' ? this.region_all[this.selected_region-1].img_th:this.region_all[this.selected_region-1].img_en);
            }else{
                this.imgRegion = this.baseFront+(this.lang == 'th' ? this.buildinshowitem[0].img_thumb_th:this.buildinshowitem[0].img_thumb_en);
            }
            
        },
        fillterBuild(){
            
            //this.swiperB.removeAllSlides()
            //alert(this.swiperB)
            this.currBuild = 0
            this.buildinshow = []
            if(this.province_selected != ""){
                // alert("Sel")
                for(let i = 0 ; i < this.building.length;i++){
                    if(this.selected_region == this.building[i].id_sector && this.province_selected == this.building[i].id_zone){
                        this.buildinshow.push(this.building[i]);
                    }
                }
            }else{
                // alert(this.buildinshow.length);
                // alert("All"+this.selected_region)
                // console.log("this.building")
                // console.log(this.building)
                for(let i = 0 ; i < this.building.length;i++){
                    if(this.selected_region == this.building[i].id_sector){
                        this.buildinshow.push(this.building[i]);
                    }
                }
                // alert(this.buildinshow.length);
                // alert(this.currBuild);
            }
            this.showBuilditem();
            
           
           //alert(this.buildinshowitem.length);
            
            
            // var swiper2 = new Swiper(".swiper2", {
            //     initialSlide: 0,
            //     allowTouchMove: false,
            //     touchRatio: 0,
            //     navigation: {
            //         nextEl: ".swiper2-button-next",
            //         prevEl: ".swiper2-button-prev",
            //     },
                
            // });
            // swiper2.slideTo(1,1);
            // this.swiperB = swiper2;
            

           
        },
        initSwiper2(){
            // var swiper = new Swiper(".swiper1", {
            //     slidesPerView: 1,
            //     spaceBetween: 30,
            //     pauseOnMouseEnter: false,
            //     navigation: {
            //         nextEl: ".swiper1-button-next",
            //         prevEl: ".swiper1-button-prev",
            //     },
            //     on: {
            //         slideChange: this.mainSwipperChange,
            //   },
            // });
            // this.swiperA = swiper;
            this.imgRegion = this.region_all[0]
            this.showBuilditem()
        },
        filltersection(id){
            this.currBuild = 0
            this.provinceShow= []

            let temProvince = []
            for(let i = 0 ; i < this.building.length;i++){
                if((id) == this.building[i].id_sector){
                    temProvince.push(this.building[i].id_zone);
                }

            }
            
            let uniqueProvince = temProvince.filter((c, index) => {
                return temProvince.indexOf(c) === index;
            });
            
            
            for(let i = 0 ; i < uniqueProvince.length;i++){
                for(let j = 0 ; j < this.province.length;j++){
                    if((uniqueProvince[i]) == this.province[j].id){
                        this.provinceShow.push(this.province[j]);
                    }
                }
            }
            
            this.province_selected=""
            
            this.fillterBuild();
           
        },
        callsection(id){
            // this.currBuild = 0
            //this.swiperA.slideTo(id-1, 1)
            this.mainSwipperChange(id);
            // alert(this.selected_region)
            // this.filltersection(this.selected_region)
        },
        mainSwipperChange(id){
            this.currBuild = 0
            this.selected_region = id
            //  alert("aaaaaa"+this.selected_region)
            this.filltersection(this.selected_region)
        },
        footprintSwipperChange(){
           
        },
        find_sector(id) {
            return this.region_all.find(x => x.id == id)
        },
        find_product(id) {
            return this.product.find(x => x.id == id)
        },
        find_zone(id) {
            return this.province.find(x => x.id == id) ? this.province.find(x => x.id == id):{name_th: "-",name_en: "-"}
        },
        find_type_building(id) {
            return this.type_build.find(x => x.id == id) ? this.type_build.find(x => x.id == id):{name_th: "-",name_en: "-"}
        },
        async fetchMaster() {
            await this.$axios.get("/api/show/map_zone")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.province = response.data.data
                this.fetchBuilding()
                    }
                })
        },
        async fetchRegion() {
            await this.$axios.get("/api/show/map_sector")
                .then((response) => {
                    if (response.data.code == 200) {
                        // this.region[0] = firstPart
                        // this.region[1] = secondPart
                        this.region_all = response.data.data
                        this.region = response.data.data.slice(0, 4)
                        this.region2 = response.data.data.slice(4)
                        // console.log("............")
                        // console.log(this.region)
                         console.log(this.region2)
                        this.fetchMaster()
                    }
                })
        },
        async fetchBuilding() {
            await this.$axios.get("/api/show/map_footprint")
                .then((response) => {
                    if (response.data.code == 200) {
                        // this.region[0] = firstPart
                        // this.region[1] = secondPart
                        this.building = response.data.data
                        // console.log( this.building);
                        this.initSwiper2();
                    }
                })
        },
        async fetchProduct() {
            await this.$axios.get("/api/show/product")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.product = response.data.data
                    }
                })
        },
    },
    mounted() {
        
        this.fetchRegion()
       
        
        // var swiper2 = new Swiper(".swiper2", {
        //     initialSlide: 0,
        //     allowTouchMove: false,
        //     touchRatio: 0,
        //     // navigation: {
        //     //     nextEl: ".swiper2-button-next",
        //     //     prevEl: ".swiper2-button-prev",
        //     // },
            
        // });

        //this.swiperB = swiper2;
        // function getDirection() {
        //     alert(1342132131)
        //    //console.log($this)
        //    console.log(this)
        //   }
        
     
        // swiper.on('slideChange', function () {
        //     alert('slide changed');
        //   });
          
    },
    components: {
        Footer, Header, Breadcrumb, Toolbar
    }
};